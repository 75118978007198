import $ from "jquery";
import modules from "ym";
import "./modules/stick";
import { language } from "./lib/language";
import declension from "./lib/declension";

(function () {
  var wrapper = $("<div />");

  $(document).on("click", ".eo-item__promote-open", function () {
    var item = $(this).closest(".eo-item");
    var promote = item.find(".submenu-promote");

    promote.removeAttr("data-dd-event");
    promote.attr("data-dd-permanent", 1);
    promote.attr("data-dd-auto", 1);

    if (!promote.is(".est-dropdown-toggle-active")) {
      promote.trigger("click");
    }
  });

  $(document).on("click", ".eo-item__reset-hits", function () {
    var item = $(this).closest(".eo-item");
    var url = $(this).attr("data-nohits-url");

    $.ajax({
      url: url,
      dataType: "jsonp",
      data: {
        sf_format: "jsonp",
      },
      success: function (data) {
        wrapper.html(data);
        item.find(".eo-item-view").replaceWith(wrapper.find(".eo-item-view"));
        item
          .find(".eo-item__promote-dropdown .est-dropdown__content")
          .replaceWith(
            wrapper.find(".eo-item__promote-dropdown .est-dropdown__content")
          );
      },
    });
  });
})();

// Открытие диалога покупки показов объявления
$(document).on("estdialogopen", ".dialog-ads-app.dialog-buy-form", function () {
  var dialog = $(this);
  var default_radio_inputs = dialog.find('.default input[type="radio"]');
  var manual_input = dialog.find('.manual input[type="text"]');
  var final_clicks_num = dialog.find(
    '.final-values input[name="final-clicks-num"]'
  );
  var final_sum = dialog.find('.final-values input[name="final-sum"]');
  var final_price_per_click = dialog.find(
    '.final-values input[name="final-price"]'
  );
  var record_id = dialog.find('.final-values input[name="final-record-id"]');
  var clicks_num_visible = dialog.find(".final-info .num");
  var final_sum_visible = dialog.find(".final-info .sum");
  var money_avail = dialog.find(".est-my-money__avail");
  var warning = dialog.find(".warning-not-enough");
  var button_submit = dialog.find(".ui-button");
  var default_num = dialog.find(".choose-clicks").attr("data-default-num");

  function init_warning() {
    var count_sum = final_sum.val();
    var avail_sum = money_avail.attr("data-money-avail");

    if (parseInt(count_sum) > avail_sum) {
      warning.removeClass("hidden");
      button_submit.addClass("disabled");
    } else {
      warning.addClass("hidden");
      button_submit.removeClass("disabled");
    }
  }

  // only integer values are let to be entered
  manual_input.attr("data-input-pattern", "^[1-9]\\d{0,4}$");

  default_radio_inputs
    .filter('[value="' + default_num + '"]')
    .prop("checked", true)
    .trigger("change");
  default_radio_inputs.on("change", function () {
    manual_input.val([]);

    if (this.checked) {
      var value = $(this).val();
      default_num = value;
      final_clicks_num.val(value).trigger("change");
    }
  });

  manual_input.on("input keyup keypress change", function () {
    var current_val = parseInt($(this).val()) || 0;
    if (current_val) {
      default_radio_inputs.prop("checked", false);
      final_clicks_num.val(current_val).trigger("change");
    } else {
      default_radio_inputs
        .filter('[value="' + default_num + '"]')
        .prop("checked", true)
        .trigger("change");
    }
  });

  final_clicks_num.on("change", function () {
    var clicks_num = $(this).val();
    var price_per_click = final_price_per_click.val();
    var count_sum = clicks_num * price_per_click;
    count_sum = Math.round(parseFloat(count_sum) * 100) / 100;

    if (language === "ru") {
      dialog
        .find(".final-count .declension")
        .html(declension(clicks_num, ["переход", "перехода", "переходов"]));
    } else {
      dialog
        .find(".final-count .declension")
        .html(declension(clicks_num, ["перехід", "перехода", "переходів"]));
    }

    final_sum.val(count_sum);
    clicks_num_visible.html(clicks_num);
    final_sum_visible.html(count_sum);

    init_warning();
  });

  button_submit.on("click", function () {
    if ($(this).hasClass("disabled")) {
      return false;
    }

    const url_submit = $(this).attr("data-url-submit");

    $.ajax({
      url: url_submit,
      dataType: "jsonp",
      data: {
        clicks_num: final_clicks_num.val(),
        sum: final_sum.val(),
        record_id: record_id.val(),
        sf_format: "jsonp",
        sf_culture: $("html").attr("lang"),
      },
      success: function (data) {
        dialog.estdialog("close");
        const buy_traffic_success = $($.parseHTML(data));

        buy_traffic_success.appendTo(document.body);
        buy_traffic_success.estdialog({
          destroyOnClose: true,
          resizable: false,
          autoOpen: true,
          modal: true,
          width: 670,
          close: function () {
            const bought_clicks = buy_traffic_success.find(".num");
            const traffic_buy_container = $("#eo-id-" + record_id.val()).find(
              ".link-list .item-buy-ad"
            );
            traffic_buy_container.find(".already-bought-apps").remove();
            traffic_buy_container.append(
              '<span class="already-bought-apps">' +
                bought_clicks.text() +
                "</span>"
            );
            traffic_buy_container
              .find("b.ads-total")
              .html(
                parseInt(traffic_buy_container.find("b.ads-total").html()) +
                  parseInt(bought_clicks.text().replace(/^\D*(\d+)/gi, "$1"))
              );
          },
        });
      },
    });
  });

  init_warning();
});

(function () {
  const eo_list_table_head = $(
    '<table class="eo-table-head"><thead><tr></tr></thead></table>'
  );
  const eo_list_sticky_head = $(
    '<div class="eo-list-sticky-head"></div>'
  ).append(eo_list_table_head);

  const recalc = function () {
    $(".eo-table")
      .find(".eo-table-head__col")
      .each(function (index) {
        const th_src = $(this);
        const column = th_src.attr("data-column");
        const th_res = eo_list_table_head
          .find('.eo-table-head__col[data-column="' + column + '"]')
          .width(th_src.width());
      });
  };

  $(function () {
    const table = $(".eo-table");
    const stub = $(".eo-table-stub");

    if (table.length && !stub.length) {
      modules.require(["stick"], function (stick) {
        const $window = $(window);

        table
          .css(
            "margin-top",
            parseInt(table.css("margin-top")) - table.find("thead").height()
          )
          .before(eo_list_sticky_head);
        eo_list_sticky_head
          .find("thead tr")
          .empty()
          .append(table.find("thead tr").children().clone());
        $window.on("resize", recalc);
        $window.on("scroll", function () {
          table
            .find(".est-dropdown-toggle-active")
            .removeClass("est-dropdown-toggle-active")
            .trigger("dropdown-deactivated");
        });

        recalc();

        stick(eo_list_sticky_head.get(0), {
          sticky_class: "is-stuck",
          offset_top: 0,
        });
      });
    }
  });
})();
