import modules from "ym";
import "./jquery";

modules.define("stick", ["jquery"], (provide, $) => {
  import(
    /* webpackChunkName: 'sticky-kit' */ "sticky-kit/dist/sticky-kit.js"
  ).then(() => {
    if ($.fn && $.fn.stick_in_parent) {
      provide((element, options) => {
        $(element).stick_in_parent(options);
      });
    }
  });
});
